import React from 'react'

import Layout from '../components/layout'

import Banner from '../components/banner'
import Title from '../components/title'
import ContactUs from '../components/contactus'

const contactUsPage = () => (
  <Layout>
    <Banner image={'/images/contact-us.jpg'} />
    <Title desc={`CONTACT US`}>联系我们</Title>
    <div className="container">
      <ContactUs />
    </div>
  </Layout>
)

export default contactUsPage
